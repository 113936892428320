require.resolve("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./en.js?sporks");

export default {
  mode: "lazy",
  source: "i18n-data/data/locales",
  context: {
    "bn": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./bn.js?sporks" /* webpackChunkName: "i18n-data-data-locales-bn" */); },
    "cs": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./cs.js?sporks" /* webpackChunkName: "i18n-data-data-locales-cs" */); },
    "da": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./da.js?sporks" /* webpackChunkName: "i18n-data-data-locales-da" */); },
    "de": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./de.js?sporks" /* webpackChunkName: "i18n-data-data-locales-de" */); },
    "el": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./el.js?sporks" /* webpackChunkName: "i18n-data-data-locales-el" */); },
    "en-au": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./en-au.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en-au" */); },
    "en-ca": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./en-ca.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en-ca" */); },
    "en-gb": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./en-gb.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en-gb" */); },
    "en-ie": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./en-ie.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en-ie" */); },
    "en-in": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./en-in.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en-in" */); },
    "en-nz": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./en-nz.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en-nz" */); },
    "en-us": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./en-us.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en-us" */); },
    "en": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./en.js?sporks" /* webpackChunkName: "i18n-data-data-locales-en" */); },
    "es-ar": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./es-ar.js?sporks" /* webpackChunkName: "i18n-data-data-locales-es-ar" */); },
    "es-mx": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./es-mx.js?sporks" /* webpackChunkName: "i18n-data-data-locales-es-mx" */); },
    "es": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./es.js?sporks" /* webpackChunkName: "i18n-data-data-locales-es" */); },
    "fi": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./fi.js?sporks" /* webpackChunkName: "i18n-data-data-locales-fi" */); },
    "fr-ca": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./fr-ca.js?sporks" /* webpackChunkName: "i18n-data-data-locales-fr-ca" */); },
    "fr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./fr.js?sporks" /* webpackChunkName: "i18n-data-data-locales-fr" */); },
    "he-il": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./he-il.js?sporks" /* webpackChunkName: "i18n-data-data-locales-he-il" */); },
    "hr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./hr.js?sporks" /* webpackChunkName: "i18n-data-data-locales-hr" */); },
    "hu": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./hu.js?sporks" /* webpackChunkName: "i18n-data-data-locales-hu" */); },
    "id": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./id.js?sporks" /* webpackChunkName: "i18n-data-data-locales-id" */); },
    "it": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./it.js?sporks" /* webpackChunkName: "i18n-data-data-locales-it" */); },
    "ja": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./ja.js?sporks" /* webpackChunkName: "i18n-data-data-locales-ja" */); },
    "ko": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./ko.js?sporks" /* webpackChunkName: "i18n-data-data-locales-ko" */); },
    "lt": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./lt.js?sporks" /* webpackChunkName: "i18n-data-data-locales-lt" */); },
    "ms": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./ms.js?sporks" /* webpackChunkName: "i18n-data-data-locales-ms" */); },
    "nl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./nl.js?sporks" /* webpackChunkName: "i18n-data-data-locales-nl" */); },
    "no-no": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./no-no.js?sporks" /* webpackChunkName: "i18n-data-data-locales-no-no" */); },
    "pl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./pl.js?sporks" /* webpackChunkName: "i18n-data-data-locales-pl" */); },
    "pt-br": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./pt-br.js?sporks" /* webpackChunkName: "i18n-data-data-locales-pt-br" */); },
    "pt-pt": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./pt-pt.js?sporks" /* webpackChunkName: "i18n-data-data-locales-pt-pt" */); },
    "ro": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./ro.js?sporks" /* webpackChunkName: "i18n-data-data-locales-ro" */); },
    "ru": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./ru.js?sporks" /* webpackChunkName: "i18n-data-data-locales-ru" */); },
    "sk": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./sk.js?sporks" /* webpackChunkName: "i18n-data-data-locales-sk" */); },
    "sv": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./sv.js?sporks" /* webpackChunkName: "i18n-data-data-locales-sv" */); },
    "th": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./th.js?sporks" /* webpackChunkName: "i18n-data-data-locales-th" */); },
    "tl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./tl.js?sporks" /* webpackChunkName: "i18n-data-data-locales-tl" */); },
    "vi": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./vi.js?sporks" /* webpackChunkName: "i18n-data-data-locales-vi" */); },
    "zh-cn": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./zh-cn.js?sporks" /* webpackChunkName: "i18n-data-data-locales-zh-cn" */); },
    "zh-hk": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./zh-hk.js?sporks" /* webpackChunkName: "i18n-data-data-locales-zh-hk" */); },
    "zh-tw": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/@hs/babel-7-20-loader/loader.js??ruleSet[1].rules[6].oneOf[1].use[0]!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1573/node_modules/bender-webpack/lib/use-es6-loader.js!./zh-tw.js?sporks" /* webpackChunkName: "i18n-data-data-locales-zh-tw" */); }  }
};
